import React from "react";


function TermsConditionMobile() {
    return (
        <div className="container terms">
            <div className="row">
                <div className="col-lg-12">
                    <h2>Terms & Conditions</h2>
                    <div>
                        <h6 style={{ fontFamily: "DejaVuSansBold" }}>1. ACCEPTANCE OF TERMS</h6>
                        <p className="termsfont">1.1. KeyWe provides its software and services (“Subscription”) as defined in the Subscription Agreement (“Agreement”) to the person or entity and its users (“Customer”, “You” or “you”) identified on the Agreement subject to these terms and conditions (“T&Cs”), as may be amended from time to time</p>
                        <p className="termsfont">1.2. By signing the Agreement or by accessing the Subscription, Customer represents and acknowledges Customer has read, understood, and agrees (a) to be bound by these T&Cs, and the provisions of the Agreement, (b) that the information Customer provides is accurate, complete, and is within Customer’s right to use. If Customer is a company or another legal entity, the individual signing on behalf of the Customer represents that he or she has the authority to bind Customer and its affiliates to these T&Cs.</p>
                        <p className="termsfont">1.3. Customer acknowledges that these T&Cs constitute a contractual agreement between Customer and KeyWe, are incorporated into the Agreement, and that these T&Cs govern Customer’s use of the Subscription and super cede any other agreements between Customer and KeyWe.</p>
                    </div>
                    <div>
                        <h6 style={{ fontFamily: "DejaVuSansBold" }}>2. PURCHASED SUBSCRIPTION</h6>
                        <p className="termsfont">2.1. KeyWe shall make available to Customer during the Subscription Term, as defined in Section 7.1, the purchased Subscription as specified in Exhibit 1 of the Agreement and pursuant to these T&Cs.</p>
                        <p className="termsfont">2.2. KeyWe shall use commercially reasonable efforts to make the Subscription available 24 hours a day, 7 days a week, except for: (a) planned downtime, (b) any unavailability caused by circumstances beyond KeyWe’s reasonable control, including without limitation, acts of God, acts of government, floods, fKeyWes, earthquakes, civil unrest, acts of terror, strikes or other labor problems, listings feed outages or failures, Internet service provider failures or delays, or denial of service attacks. KeyWe’s obligations shall be limited to providing the Subscription only in accordance with applicable laws and government regulations. Customer acknowledges that the Subscription may be subject to other limitations, such as: (i) limits on disk storage space, (ii) the number of calls KeyWe’s customers are permitted to make against KeyWe’s application programming interface, and (iii) limitations imposed by third party service providers enabling the Customer to provide public websites with limited page views.</p>
                        <p className="termsfont">2.3. Customer is limited to the total of one-thousand (1,000) contacts per agent multiplied by the number of agents covered by this Agreement, unless approved in writing by KeyWe.</p>
                    </div>
                    <div>
                        <h6 style={{ fontFamily: "DejaVuSansBold" }}>3. OBLIGATIONS & RIGHTS SURROUNDING CUSTOMER DATA</h6>
                        <p className="termsfont">3.1. Protection of Data: KeyWe shall maintain commercially reasonable levels of administrative, physical, and technical safeguards for protection of the security, confidentiality, and integrity of data, content and media supplied by Customer for inclusion in the Subscription (“Customer Data”).</p>
                        <p className="termsfont">3.2. Customer Data Rights: Subject to the limited rights granted by Customer hereunder, KeyWe acquires no right, title or interest from Customer or Customer licensors under these T&Cs in or to Customer Data, including any intellectual property rights therein. Customer grants KeyWe the right to use non-Customer specific aggregated data for use by KeyWe to create business and best practice metrics. Further, KeyWe does not claim copyright to Customer Data, and KeyWe shall not be held liable for Customer Data on the website or any uses made of Customer Data.</p>
                        <p className="termsfont">Customer represents and warrants to KeyWe that Customer has all necessary rights, permissions, licenses and other authority to use the Customer Data as anticipated under these T&Cs. Customer shall indemnify and hold harmless KeyWe and its owners, officers, managers, members, employees, agents, contractors, successors and assigns from and against any damages, claims, injury, costs and expenses, including, without limitation, attorney fees and court costs, arising out of the use of Customer Data as anticipated under these T&Cs. KeyWe reserves the right to suspend or remove Customer’s website or portions thereof if KeyWe determines, in its sole discretion, that (i) significant doubt exists as to Customer’s right to use any portion of Customer Data, (ii) any Customer Data is offensive, immoral, obscene, illegal, or likely to incite or encourage illegal or dangerous acts, or (iii) any Customer Data may harm KeyWe’s reputation or hinder its ability to provide its services to other customers.</p>
                        <p className="termsfont">3.3. Return of Customer Data. Upon written request by Customer made within thirty (30) days after the date of termination of the Subscription Term and payment in full of all outstanding fees, KeyWe shall make available to Customer for download a file of Customer Data that KeyWe has defined at the time as exportable. Exportable Customer Data may include some or all agent, contact and lender information; front end website style design settings and files which the Customer has paid for through a Custom Design & Layout Work; images owned by Customer. Additionally, Customer may be able to receive or download periodic backups of some or all of the Customer Data throughout the Subscription Term based on a request to the KeyWe support team, no more often than quarterly. KeyWe shall have no obligation to maintain or provide any Customer Data and may delete Customer Data after the aforementioned thirty (30) day period.</p>
                    </div>
                    <div>
                        <h6 style={{ fontFamily: "DejaVuSansBold" }}>4. PROPRIETARY RIGHTS</h6>
                        <p className="termsfont">4.1. Reservation of Rights in Subscription: Subject to the limited rights expressly granted hereunder, KeyWe reserves all rights, title and interest in and to the Subscription, including all related intellectual property rights. No rights are granted to Customer hereunder other than as expressly set forth herein.</p>
                        <p className="termsfont">Copyright to the finished website design, other design-based elements, and special functionality produced by KeyWe are owned by KeyWe or used by KeyWe by permission. Once payment under the Agreement and these T&Cs, including any additional charges incurred, has been received, Customer will be assigned rights to use the Subscription subject to these T&Cs. Rights to photos, graphics, source code, work-up files, and computer programs specifically are not transferred to Customer, and remain the property of their respective owners. In cases where customers pay for customized cascading style sheets (“CSS”), both Customer and KeyWe will have rights to future use of those CSS files and hereby grant each other reciprocal, royalty-free, worldwide, irrevocable, perpetual licenses for such use. KeyWe, its licensors, employees and subcontractors retain the ownership, copyright, and distribution rights to themes, templates, designs and any other products or derivatives of such work; and retain the right to display graphics and other web design elements as examples of their work in their respective portfolios. Notwithstanding anything to the contrary contained herein, any use by Customer granted in these T&Cs of intellectual property described in this Section 5.1 shall be deemed to be used under a license (or sublicense, as the case may be) hereby granted by KeyWe subject to the payments required herein and to KeyWe’s right to terminate such license by terminating the Agreement as allowed hereunder.</p>
                        <p className="termsfont" >4.2. Restrictions: Customer shall not personally use the Subscription, or allow others to access the Subscription to, (a) create derivative works based on the Subscription except as authorized herein, (b) copy, frame or mirror any part or content of the Subscription, other than copying or framing on Customer’s own intranets for internal business purposes, (c) reverse engineer the Subscription, (d) facilitate or allow mass communications such as email or text message ‘blasts’ to contacts that are have not expressly opted-in to such communications, (e) use any part of the Subscription in ways that can be considered an abuse of the Subscription or (f) build a competitive product or service, or (g) copy any features, functions or graphics of the Subscription.</p>
                        <p className="termsfont">4.3. Suggestions: KeyWe shall have a royalty-free, worldwide, irrevocable, perpetual license to use and incorporate into the Subscription and its design and functionalities, whether for Customer use or for any other uses, any suggestions, enhancement requests, recommendations or other feedback provided by Customer, including users, relating to the operation of the Subscription.</p>
                        <p className="termsfont">4.4. TCPA Notice and Disclaimer: Transmitting unsolicited voice and text messages (as well as other forms of communication) is heavily restricted and regulated under the Telephone Consumer Protection Act (“TCPA”) and other laws as well as various jurisdictions’ laws and regulations. You should consult your legal advisor to ensure compliance with the TCPA and related laws. KeyWe makes no representations regarding the content and manner of transmission of any text, phone or other communication you may make.</p>
                        <p className="termsfont">4.5. Customer Communications & Compliance with Laws. Customer is solely responsible for the content of any and all communications and the means of communication (phone, fax, text, etc.) with any third parties, including customers, potential customers, leads or other individuals or entities, and Customer is solely responsible for complying with any laws, Taxes and tariffs applicable in any way to the Subscription or any other services contemplated herein.</p>
                    </div>
                    <div>
                        <h6 style={{ fontFamily: "DejaVuSansBold" }}>5. DISCLAIMERS AND LIMITATION OF LIABILITY</h6>
                        <p className="termsfont">EXCEPT AS EXPRESSLY PROVIDED HEREIN, KEYWE MAKES NO WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, AND SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING ANY WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW. IN NO EVENT SHALL KEYWE’S AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THE AGREEMENT AND THESE T&CS (WHETHER IN CONTRACT OR TORT OR UNDER ANY OTHER THEORY OF LIABILITY) EXCEED THE TOTAL AMOUNT PAID BY CUSTOMER HEREUNDER DURING THE PREVIOUS 12 MONTHS. THE FOREGOING SHALL NOT LIMIT CUSTOMER PAYMENT OBLIGATIONS UNDER SECTION 4 (FEES AND PAYMENT FOR SUBSCRIPTION). IN NO EVENT SHALL IRE HAVE ANY LIABILITY FOR ANY LOST PROFITS OR REVENUES OR FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, COVER OR PUNITIVE DAMAGES HOWEVER CAUSED, WHETHER IN CONTRACT, TORT OR UNDER ANY OTHER THEORY OF LIABILITY, AND WHETHER OR NOT KEYWE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING DISCLAIMER SHALL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.</p>
                    </div>
                    <div>
                        <h6 style={{ fontFamily: "DejaVuSansBold" }}>6. TERM AND TERMINATION</h6>
                        <p className="termsfont">6.1. Subscription Term: The Subscription Term shall include the Implementation Term, the Initial Subscription Term and Renewal Term(s). The Implementation Term shall commence upon the date of this Agreement through the launch date of the Customer agent websites. The Initial Subscription Term shall commence as of the launch date of the Customer agent websites through the Number of Subscription Months specified above. The Agreement will automatically renew at the end of the Initial Subscription Term for an additional Renewal Period equivalent to the Initial Subscription Term (each, a “Renewal Period”) unless Customer provides a written cancellation notice to Inside Real Estate (KeyWe) at least thirty (30) days prior to the end of the Initial or Renewal Term(s).</p>
                        <p className="termsfont">6.2. Termination: The Agreement may be terminated within the Initial Subscription or Renewal Term(s): (a) by either party if the other party becomes the subject of a petition in bankruptcy or any other proceeding relating to insolvency, receivership, liquidation or assignment for the benefit of creditors, (b) by KeyWe immediately upon Customer’s failure to make any payment required under the Agreement or these T&Cs, or (c) in the event of an uncured material breach, which material breach must be documented as such to the breaching party with a sixty (60) day notice that the breach is material; if the noted breach goes uncured for sixty (60) days from date of notice, then such applicable party may then terminate immediately upon written notice to the other party.</p>
                        <p className="termsfont">6.3. Payment upon Termination: Upon termination by KeyWe under Section 7.2, Customer shall pay any unpaid fees covering the remainder of the Subscription Term for all Subscriptions. In no event shall any termination by KeyWe pursuant to Section 7.2 above relieve Customer of the obligation to pay any fees payable to KeyWe hereunder for the full Subscription Term.</p>
                        <p className="termsfont">6.4. Surviving Provisions. Sections 2, 3, 4, 5, 6, 7, and 8 of these T&Cs shall survive any termination or expiration of the Agreement.</p>
                        <p className="termsfont">6.5. Notices. Except as otherwise specified in these T&Cs, all notices, permissions and approvals hereunder shall be in writing and shall be deemed to have been given upon: (a) personal delivery, (b) the second business day after mailing, or (c) the first business day after sending by email.</p>
                    </div>
                    <div>
                        <h6 style={{ fontFamily: "DejaVuSansBold" }}>7. GENERAL PROVISIONS</h6>
                        <p className="termsfont">7.1. Independent Contractors. The parties are independent contractors. Neither the Agreement nor these T&Cs creates a partnership, franchise, joint venture, agency, brokerage, fiduciary or employment relationship between the parties.</p>
                        <p className="termsfont">7.2. Third-Party Beneficiaries. There are no third-party beneficiaries to the Agreement or these T&Cs.</p>
                        <p className="termsfont">7.3. No Waiver; Severability. No failure or delay by either party in exercising any right under the Agreement or these T&Cs shall constitute a waiver of that right. If any provision of the Agreement or these T&Cs is held by a court of competent jurisdiction to be contrary to law, the provision shall be modified by the court and interpreted so as best to accomplish the objectives of the original provision to the fullest extent permitted by law, and the remaining provisions of the Agreement and these T&Cs shall remain in effect.</p>
                        <p className="termsfont">7.4. Entire Agreement. These T&Cs, including all exhibits and addenda hereto, and the Agreement constitute the entire agreement between the parties and supersedes all prior and contemporaneous agreements, proposals or representations, written or oral, concerning its subject matter. Notwithstanding any language to the contrary therein, no terms or conditions stated in Customer purchase orders or other order documentation (excluding Subscription Agreements) shall be incorporated into or form any part of these T&Cs, and all such terms or conditions shall be null and void.</p>
                        <p className="termsfont">7.5. Amendment to Agreement; Changes to These T&Cs. No modification, amendment, or waiver of any provision of the Agreement shall be effective unless in writing and either signed or accepted electronically by the party against whom the modification, amendment or waiver is to be asserted.</p>
                        <p className="termsfont">To the extent of any conflict or inconsistency between the provisions in the body of these T&Cs and any exhibit or addendum hereto or any Agreement, the terms of such Agreement shall prevail provided such conflicting Agreement has been signed or accepted by KeyWe. KeyWe may evidence its acceptance of an Agreement either by countersigning and returning a copy to Customer or by beginning to provide the services requested thereunder and charging Customer as described therein. </p>
                        <p className="termsfont">7.6. Governing Law and Jurisdiction: Each party agrees that these T&Cs shall be governed by and construed under the laws of the State of Utah without regard to such state’s choice or conflicts of law rules. The parties further agree to the exclusive jurisdiction of the applicable courts located in Salt Lake County, Utah. In any action to enforce or interpret these T&Cs, the substantially prevailing party shall be entitled to recover its attorney fees and court costs in addition to any other remedy to which it is entitled, whether such fees and costs are incurred in mediation, arbitration, litigation or on appeal.</p>
                    </div>
                </div>
            </div>
        </div>



    )
}
export default TermsConditionMobile